<template>
    <div class="content-box">
      <div class="content-header">
        <el-date-picker
            class="date-picker"
            v-model="queryForm.times"
            type="daterange"
            range-separator="-"
            start-placeholder="绑定开始日期"
            end-placeholder="结束日期">
            clearable
        </el-date-picker>

        <el-input v-model="queryForm.deviceClass" placeholder="请输入设备型号" class="input"></el-input>
        <el-input v-model="queryForm.phoneOrNickname" placeholder="请输入用户手机号或昵称" class="input"></el-input>

        <el-button type="plain" class="cotent-btn" @click="handleSearch">搜索</el-button>
      </div>
      <div class="table-list">
        <ETable :columns="columns" :data="tableData" style="width: 100%" :max-height="tableMaxheight" :header-cell-style="headerCellStyle" :cell-style="cellStyle" border
            v-loading="loading">
            <template #nickname="{row}">
              <div class="_flex user-info__box" v-if="row.nickname">
                <el-avatar shape="circle" :size="50" fit="content" :src="row.icon"></el-avatar>
                <div class="_flex user-info__box--basic">
                  <span>{{row.nickname}}</span>
                  <span>{{row.phone}}</span>
                </div>
              </div>
            </template>
            <template #action="{row}">
              <el-button type="text" @click="viewBindRecord(row)">绑定记录</el-button>
            </template>
        </ETable>
        <!-- 分页 -->
        <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>
      </div>
      <el-dialog title="绑定记录" :visible.sync="dialogVisible" width="700px" :before-close="dialogClose" :close-on-click-modal="false">
          <div class="edit-box">
            <el-table :data="bindRecordList" border max-height="500">
              <el-table-column label="操作时间" prop="createdTime">
              </el-table-column>
              <el-table-column label="操作" prop="action">
                <template #default="{row}">
                  <div class="_flex">
                    <div :class="row.bindingStatus == 1 ? 'disable-btn' : 'enable-btn'">
                      {{row.bindingStatus == 1 ? '绑定' : '解绑'}}
                    </div>
                    <div class="_flex user-info__box" v-if="row.bindingStatus == 1">
                      <el-avatar shape="circle" :size="50" fit="content" :src="row.icon"></el-avatar>
                      <div class="_flex user-info__box--basic">
                        <span>{{row.nickname}}</span>
                        <span>{{row.phone}}</span>
                      </div>
                    </div>
                  </div>

                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
    </div>
  </template>
  
  <script>
  import { Watch, Mixins, Component } from "vue-property-decorator";
  import tablePagination from "@/mixins/tablePagination";
  import loading from "@/mixins/loading";
  import Resize from "@/mixins/resize";
  
  import { watchConfigList, getBindRecords } from "@/api/watch"
  import dayjs from "dayjs";
  
  // 常量
  import { ServiceStatusEnum } from "@/enum/service.enum";

  import ETable from "@/components/ETable.vue";
  
  @Component({
    components: { ETable }
  })
  export default class StoreList extends Mixins(loading, tablePagination, Resize) {
    serviceStatusEnum = ServiceStatusEnum
  
    queryForm = { // 查询参数
        times: [],
        deviceClass: "",
        phoneOrNickname: "",
    }
  
    tableData = [{}];
    itemTotal = 0;
  
    dialogVisible = false;
    dialogTitle = "";
    
    columns = [
        { label: "设备编号", prop: "serialNo" },
        { label: "设备型号", prop: "deviceClass" },
        { label: "当前绑定用户", prop: "nickname" },
        { label: "最后绑定时间", prop: "lastBindingTime" },
        { label: "最后数据上传时间", prop: "lastUploadTime" },
        { label: "操作", prop: "action" },
    ]
    
    bindRecordList = [];
  
  
    mounted() {
      this.windowResize(310)
      this.getData();
    }
    getData() {
      this.showLoading();
      const { deviceClass, phoneOrNickname } = this.queryForm;
      watchConfigList({
        deviceClass,
        phoneOrNickname,
        startDate: this.queryForm.times?.[0] ? dayjs(this.queryForm.times?.[0]).format("YYYY-MM-DD 00:00:00") : "",
        endDate:this.queryForm.times?.[1] ? dayjs(this.queryForm.times?.[1]).format("YYYY-MM-DD 23:59:59") : "",
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      }).then(res => {
        this.hideLoading();
        this.tableData = res.data.list;
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      });
    }
  
    //重置分页参数
    resetPages() {
      this.currentPage = 1;
    }
  
    // 搜索
    handleSearch() {
      this.resetPages();
      this.getData();
    }

    viewBindRecord(row) {
      this.dialogVisible = true;
      getBindRecords({ deviceClass: row.deviceClass }).then((res) => {
        this.bindRecordList = res.data;
      })
    }

    dialogClose() {
      this.dialogVisible = false;
      this.bindRecordList = [];
    }
  
  }
  </script>

  
  
  <style lang="scss" scoped>
  @import "@/styles/app";

.content-box {
  background: #edf0f9;
}

.content-header {
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dfe2e4;
  border-left: 1px solid #dfe2e4;
  border-right: 1px solid #dfe2e4;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.input {
  width: 240px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  margin-left: 12px;
}
.cotent-btn {
  margin-left: 12px;
  border: 1px solid #0054ff;
}

.cotent-btn-big {
  margin-left: 40px;
  width: 300px;
  border: 1px dashed #0054ff;
}

.table-list {
  margin-top: 20px;
  background: #ffffff;
  border-radius: 8px;
  padding: 40px 40px;
  overflow: hidden;
  box-sizing: border-box;
  min-height: calc(100% - 101px);
}
.user-info__box{
  display: flex;
  margin-left: 10px;
  &--basic{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
    &>span{
      width: 100%;
    }
  }
}
  </style>